<style>
  @import "/css/story.css";
</style>

<template>
    <div class="prices">
        <section class="style1 special">
            <div class="container">
                <div class="row">
                    <div class="col-6 col-12-medium">
                        <h2>«Que ton aliment soit ta seule médecine»<br>
                        <span style="font-size: 60%">Hippocrate</span></h2>

                    </div>
                    <div class="col-6 col-12-medium">
                        <ul class="summary">
                            <li>
                                <a href="#price_1">La consultation initiale</a>
                            </li>
                            <li>
                                <a href="#price_2">Les consultations suivantes (santé et sport)</a>
                            </li>
                            <li>
                                <a href="#price_3">Les ateliers pratiques de groupe</a>
                            </li>
                            <li>
                                <a href="#price_4">Les ateliers pratiques personnel</a>
                            </li>
                            <li>
                                <a href="#price_5">Le coaching</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        <PricesList></PricesList>
    </div>
</template>

<script>
import PricesList from '@/components/Prices/PricesList.vue'

export default {
    name: 'Prices',

    components: {
        PricesList
    }
}
</script>
