<template>
    <section id="price_1" class="style1 special">
        <div class="container">
            <h3>La consultation initiale</h3>

            <p>Lors de votre prise de contact, je vous fais parvenir un questionnaire de santé.<br>
            A notre première entrevue, nous établissons ensemble les priorités et la marche à suivre selon votre objectif.
            L'essentiel étant d'avancer ensemble dans la même direction.<br>
            Conseils, astuces, micronutrition et premières mesures vous serons remis.<br>
            Après chaque consultation, vous recevez un récapitulatif par email ainsi que les documents relatifs à notre séance.
            </p>

            <span class="price">150 CHF / 1h30</span><br><br>

            <router-link class="button" to="/contact">
                Prendre Rendez-vous
            </router-link>
        </div>
    </section>

    <section id="price_2" class="style1 special">
        <div class="container">
            <h3>Les consultations suivantes (santé et sport)</h3>

            <p>Nous discutons et adaptons les éléments mis en place lors de notre précédente consultation. L'objectif est poursuivi selon notre plan de direction.</p>

            <span class="price">100 CHF / heure</span><br><br>

            <router-link class="button" to="/contact">
                Prendre Rendez-vous
            </router-link>
        </div>
    </section>

    <section id="price_3" class="style1 special">
        <div class="container">
            <h3>Les ateliers pratiques en groupe</h3>

            <p>Les ateliers "goûters gourmands" "sans gluten,sans lactose", "alimentation vivante","lactofermentation"...<br>
            Les ateliers vous permette de mettre en pratique le mode alimentaire qui vous convient.. Ils sont pratiqués dans une cuisine professionnelle et un livret de recettes vous est remis.</p>

            <span class="price">Les tarifs sont sur demande</span><br><br>

            <router-link class="button" to="/contact">
                Prendre Rendez-vous
            </router-link>
        </div>
    </section>

    <section id="price_4" class="style1 special">
        <div class="container">
            <h3>Les ateliers pratiques personnel</h3>

            <p>Selon votre demande, maladie, intolérance ou allergies nous définissons et cuisinons ensemble un menu adapté à votre besoin.</p>

            <span class="price">Les tarifs sont sur demande</span><br><br>

            <router-link class="button" to="/contact">
                Prendre Rendez-vous
            </router-link>
        </div>
    </section>

    <section id="price_5" class="style1 special">
        <div class="container">
            <h3>Le coaching</h3>

            <p>Le coaching comprend des prestations telles que :</p>

            <ul>
              <li>l'accompagnement en supermarché (déchiffrage des étiquettes, sélection des aliments, alternatives en fonction de vos allergies/intolérances);</li>
              <li>une matinée de réorganisation de placards et frigo à domicile;</li>
              <li>des conférences et interventions sur différents thèmes</li>
            </ul>

            <span class="price">Les tarifs sont sur demande</span><br><br>

            <router-link class="button" to="/contact">
                Prendre Rendez-vous
            </router-link>
        </div>
    </section>
</template>
